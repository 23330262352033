$note-size: 12px;
$spacing: 1em;
$min-btn-width: 13em;
$icon-size: 1.5em;

$image-size: 7em;
$border-radius: 30px;

$green: #1baf5a;
$red: #fd0037;
$white: #fff;
$violet: #473d80;
$violet--darker: #1c1832;
$black: rgba(0, 0, 0, 0.8);
$black--transparent: rgba(0, 0, 0, 0.3);

.mining-page {
  &__modal-content {
    padding: $spacing 2em $spacing 2em;
  }

  &__modal-input {
    display: block;
    width: 100%;
  }

  &__modal-body {
    margin-bottom: $spacing;
    width: 100%;
  }

  &__modal-notes {
    text-align: center;
    width: 100%;
    margin-bottom: $spacing;
  }

  &__modal-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  &__modal-btn {
    width: 50%;
  }

  &__card-wrapper {
    width: 80%;
  }

  &__btn {
    color: $white;
    background: $black;
    border-radius: $border-radius;
    border: 2px solid $white;
    text-transform: uppercase;

    &:hover,
    &:active {
      color: $violet--darker;
      background: $white;
      border-radius: $violet;
    }

    &--gray {
      background: var(--bs-gray-600);
    }
  }

  &__claim-btn,
  &__action-btn {
    width: 100%;
  }

  &__claim-btn {
    padding: 1em;
    min-width: 10em;
  }

  &__action-btn {
    padding: 1em 1.25em;
  }

  &__header-btn {
    position: relative;
    top: -15px;
    right: 0;
    background: transparent;
    border: 0;
  }

  &__secondary-btn {
    margin-top: $spacing;
    min-width: $min-btn-width;
  }

  &__header-icon {
    height: $icon-size;
  }

  &__text {
    &--white {
      color: $white;
      line-height: 40px;
    }

    &--centered {
      text-align: center;
    }

    &--green {
      color: $green;
    }

    &--red {
      color: $red;
    }

    &--centered {
      text-align: center;
    }
  }

  &__note {
    font-size: $note-size !important;
    padding: 0 !important;
  }

  &__pc-grid {
    margin-top: $spacing;
  }

  &__card-container,
  &__pc-details,
  &__gamer-container {
    display: flex;
    flex-direction: column;
  }

  &__card-content,
  &__image-container,
  &__pc-container {
    width: 100%;
  }

  &__card-content {
    position: relative;
    display: flex;
    margin-bottom: 0.5em;
    padding-bottom: 1em;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      border-bottom: 2px dotted $white;
    }
  }

  &__pc-container,
  &__pc-details {
    margin: 0 $spacing;
  }

  &__card-image {
    width: $image-size;
    height: auto;
    margin-bottom: $spacing;
  }

  &__action-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 $spacing;
  }

  &__gamer-container {
    margin-left: 2em;
  }

  &__gamer-image {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__pc-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    margin-left: 3em;
    min-width: 20em;
  }

  &__pc-details {
    align-items: center;
  }

  &__background {
    &--violet {
      background: $violet;
    }
  }
}
