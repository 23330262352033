$red: #fd0037;
$green: #7fbc55;
$white: #fff;
$violet: #473d80;

.product-list {
  &--centered {
    justify-content: space-around;
  }

  &__text {
    &--white {
      color: $white;
    }
  }
}
