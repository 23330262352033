$green--gradient: linear-gradient(79deg, #1baf5a 0%, rgba(0, 114, 49, 1) 100%);
$green: #1baf5a;

.card {
  &--selected {
    border: 2px solid $green;
  }

  &__overlay {
    &--gray {
      background: var(--bs-gray-900);
    }
  }

  &__text {
    &--gray {
      color: var(--bs-gray-500) !important;
    }
  }

  &__image {
    &--gray {
      filter: grayscale(1.2);
    }
  }

  &__button {
    &--gray {
      background: var(--bs-gray-600);
    }
  }
}
